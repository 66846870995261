import Stack from '@mui/material/Stack';
import React from 'react';

export default function ScrollStack({innerStackProps, children, ...props}) {
   return (
      <Stack
         name='Scroll Stack'
         {...props}
         overflow={'hidden'}
         style={{scrollBehavior: 'smooth', ...(props?.style || {})}}
      >
         <Stack name='Scroll Stack Inner' {...innerStackProps} overflow={'auto'}>
            {children}
         </Stack>
      </Stack>
   );
}
